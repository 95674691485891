import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { NetworkError } from "../../utils/errors";

export interface Credentials {
  email: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

export async function login(
  payload: Credentials,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<LoginResponse> {
  const result = await client.mutate<{ login: LoginResponse }, Credentials>({
    mutation: gql`
      mutation Login($email: Email!, $password: Password!) {
        login(email: $email, password: $password) {
          accessToken
          refreshToken
        }
      }
    `,
    variables: payload,
  });

  if (result.errors) {
    throw new NetworkError("Login Error", result.errors.map((error) => error.message).join(", "));
  }

  if (result.data) {
    return result.data.login;
  }

  throw new NetworkError("Unknown error", "Login call did not return any data");
}
